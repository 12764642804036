import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReporting from '../reducers/reporting.reducer';
import { selectScenario } from "./scenario.selectors";
import { MarketingMode } from "../../views/scenario/settings/models/config.models";
import { Helpers } from 'src/app/core/shared/helpers';

export const selectReportingState = createFeatureSelector<fromReporting.State>(
  fromReporting.reportingFeatureKey
);

export const selectReports = createSelector(
  selectReportingState,
  (state: fromReporting.State) => state.reports
);

export const selectPersonalReports = createSelector(
  selectReportingState,
  (state: fromReporting.State) => state.personal
);

export const selectAllReports = createSelector(
  selectReportingState,
  (state: fromReporting.State) => [...state.reports, ...state.personal]
);

export const selectReport = (id: number) => createSelector(
  selectAllReports,
  (reports) => reports.find(report => report.id.toString() === id.toString())
);

export const loadingReportsState = createSelector(
  selectReportingState,
  (state) => state.loading
);

export const selectFilters = createSelector(
  selectReportingState,
  (state: fromReporting.State) => state.filters
);

/**
 * Custom filters per dashboard
 * @param dashboard
 */
export const selectFiltersByDashboard = (dashboard: string) => createSelector(
  selectScenario,
  (state) => {
    if (['scenario_non_daily', 'scenario'].includes(dashboard)) {
      return {
        ...(state.config.marketingV2.mode === MarketingMode.TARGET_PAYBACK && {
          'LTV DX': state.config.marketingV2.targetPayback,
          'ROAS DX': [7, state.config.marketingV2.targetPayback, 365].sort((a, b) => a - b).join(',')
        }),
      }
    }
    
    if ('retention_curve_segmented' === dashboard) {
      return Helpers.getRetentionCurveFilter(state.config.inputRange.end);
    }

    return {}
  }
);
