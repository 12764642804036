import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { AppState } from 'src/app/store';
import { selectPageHeaderTitle } from 'src/app/store/selectors/page.selectors';

export interface HeaderDetails {
  title: string;
  description?: string;
  icon?: string;
}

@Component({
  selector: 'app-product-header',
  templateUrl: './product-header.component.html',
  styleUrls: ['./product-header.component.scss']
})
export class ProductHeaderComponent implements OnInit {

  @Input() inline = true;

  public headerDetails$: Observable<HeaderDetails>;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.headerDetails$ = this.store.select(selectPageHeaderTitle).pipe(
      map((translateKeyPrefix: string) => {
        return {
          title: translateKeyPrefix + '.title',
          description: translateKeyPrefix + '.description',
          icon: translateKeyPrefix + '.icon'
        };
      })
    );
  }

}
