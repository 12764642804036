<div class="flex pb-3 pt-2" [ngClass]="{'items-center justify-between': inline, 'flex-col': !inline}">
  @if (headerDetails$ | async; as details) {
    <div>
      <h2 data-cy="page-header-title" class="font-semibold tracking-tight text-3xl" [translate]="details.title"></h2>
      @if (details.description) {
        <h3 data-cy="page-header-description" class="text-sm text-gray-500 dark:text-white/50 fade-in" [translate]="details.description"></h3>
      }
    </div>
  }
  <div class="flex items-center justify-end gap-2">
    <ng-content></ng-content>
  </div>
</div>
