
import { Directive, ElementRef, EventEmitter, OnInit, Output, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[appResize]',
})
export class ResizeDirective implements OnInit {
  @Output() onResize = new EventEmitter<ResizeObserverEntry>();

  private destroyRef = inject(DestroyRef);

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    this.resizeObservable(this.element.nativeElement).pipe(
        debounceTime(50),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe((entry) => {
        this.onResize.emit(entry);
    });
  }

  resizeObservable(element: HTMLElement): Observable<ResizeObserverEntry> {
    return new Observable((subscriber) => {
      const observer = new ResizeObserver((entries) => {
        subscriber.next(entries[0]);
      });

      observer.observe(element);

      return function unsubscribe() {
        observer.unobserve(element);
      };
    });
  }
}