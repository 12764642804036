@if ((segments$ | async)?.length > 50 && isBySegmentsChart) {
  <div class="pt-2">
    <app-banner
      [icon]="'warning'"
      [translateKey]="'report.banner.warn.segmentsLimit'">
    </app-banner>
  </div>
}
<div class="relative h-full w-full">
  @if (hideTitle) {
    <div class="absolute w-1/2 h-16 dark:bg-slate-950 bg-soft-blue top-0 left-0 z-10"></div>
  }
  <iframe height="100%" id="looksie" #lookerIframe
    class="border-none bg-soft-blue dark:bg-slate-950"
    width="100%" [src]="currentUrl | sanitize">
  </iframe>
</div>
<div class="absolute top-0 bottom-0 left-0 right-0 inset-0 z-10 flex flex-col items-center justify-center bg-soft-blue dark:bg-slate-950 text-gray-300 dark:text-slate-800 transition-all"
  [ngClass]="{
    'opacity-0 pointer-events-none': !isLoading
  }">
  <div class="flex items-center justify-center p-20 relative">
    <div class="absolute inset-0 flex items-center justify-center">
      <app-svg-images svgName="spinner" class="h-24 w-24"></app-svg-images>
    </div>
    <div>
      <img src="/assets/images/logo-small-dark.png" class="h-5 dark:hidden">
      <img src="/assets/images/logo-small.png" class="h-5 hidden dark:block">
    </div>
  </div>
</div>
